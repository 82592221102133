@import "../../../styles/presets";

@keyframes contact_rise {
  from {
    transform: translateY(-30px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.contacts {
  display: flex;

  a {
    color: black;
  }

  svg {
    width: 2em;
    height: 2em;
  }

  li + li {
    margin-left: 0.5em;
  }

  &__github, &__mail, &__telegram {
    transition: all $slow;
    fill: var(--icon-color);

    &:active {
      transform: scale(0.8);
    }
  }

  &__github {
    &:hover {
      fill: darkviolet;
    }
  }

  &__mail {
    &:hover {
      fill: forestgreen;
    }
  }

  &__telegram {
    &:hover {
      fill: dodgerblue;
    }
  }
}