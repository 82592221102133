@import "../../../styles/presets";

.color_switcher {
  width: 2.4em;
  height: 1.2em;
  border: 2px solid var(--border-color);
  border-radius: 20px;
  overflow: hidden;

  position: relative;

  &__toggle {
    position: absolute;
    width: 0.8em;
    height: 0.8em;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50%;
    transition: all $normal;
  }

  &:hover &__toggle {
    background-color: var(--accent);
  }
}

.light_colors {
  left: 3px;
  background-color: var(--back2);
  border: 2px solid var(--border-color);
}

.dark_colors {
  left: 19px;
  background-color: var(--back2);
  border: 2px solid var(--border-color);
}