@import "../../../styles/presets";

@keyframes avatar_rise {
  0% {
    transform: perspective(500px) rotateY(90deg);
  }

  80% {
    transform: perspective(500px) rotateY(-35deg);
  }

  100% {
    transform: perspective(500px) rotateY(0);
  }
}

@keyframes left_text_rise {
  from {
    transform: translateX(-100px);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes right_text_rise {
  from {
    transform: translateX(100px);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.main {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  @include scroll;
  padding: 8em 1em 5em 1em;

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
  }

  &__avatar_wrapper {
    position: relative;
    padding: 10px;
    border: 1px solid var(--separator);
    border-radius: 50%;
    background-color: var(--back2);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    transition: all $slow;
  }

  &__avatar {
    width: 140px;
    height: 140px;
    object-fit: cover;
    border-radius: 50%;
    filter: saturate(135%);
    transition: all $slow;
  }

  &__name, &__profession {
    user-select: none;
    text-align: center;
    color: var(--text-color);
    transition: all $slow;
  }

  &__name {
    font-weight: 300;
    font-size: 1.5em;
    margin-top: 1em;
  }

  &__profession {
    font-weight: 100;
    font-size: 2em;
    letter-spacing: 0;
  }

  &__more_button {
    margin-top: 1em;
  }

  &__canvas {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

@media (min-width: $medium-start) and (max-width: $medium-end) {
  .main {
    &__avatar {
      width: 170px;
      height: 170px;
    }

    &__name {
      font-size: 1.8em;
    }

    &__profession {
      font-size: 2.1em;
      letter-spacing: 3px;
    }
  }
}

@media (min-width: $large-start) {
  .main {
    &__avatar {
      width: 200px;
      height: 200px;
    }

    &__name {
      font-size: 2.1em;
    }

    &__profession {
      font-size: 2.4em;
      letter-spacing: 6px;
    }
  }
}
