@import "../../../styles/presets";

// Анимация появления меток
@keyframes label_rise {
  0% {
    transform: perspective(100px) rotateX(90deg);
  }

  80% {
    transform: perspective(100px) rotateX(-20deg);
  }

  100% {
    transform: perspective(100px) rotateX(0);
  }
}

.project_filter {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
