@import "../../../styles/presets";
@import "../../common/SkillCard/SkillCard";

.skills {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  @include scroll;
  padding: 8em 1em 5em 1em;

  .section_header {
    margin-bottom: 2em;
  }

  .skill_card {
    margin: 1em 0 0 0;
  }

  &__content {
    max-width: 55em;
    margin-left: auto;
    margin-right: auto;
  }

  &__description {
    text-align: justify;
    color: var(--text-color);
    margin-bottom: 2em;
  }

  &__skill_list {
    display: flex;
    flex-direction: column;
  }
}

@media (min-width: $medium-start) {
  .skills {
    .skill_card {
      margin: 0;
      flex: 1;
    }

    .skill_card + .skill_card {
      margin-left: 1em;
    }

    &__skill_list {
      flex-direction: row;
    }
  }
}