@import "../../../styles/presets";

.tech_label {
  font-family: sans-serif;
  font-size: 0.8em;
  background-color: deepskyblue;
  color: white;
  border-radius: 10px;
  padding: 3px 10px;
  cursor: pointer;
  user-select: none;
  transition: all $slow;

  &:hover {
    background-color: dodgerblue;
    color: white;
  }
}