@import "../../../styles/presets";
@import "../../common/MenuButton/MenuButton";

@keyframes menu_rise {
  from {
    top: -3em;
  }

  to {
    top: 0;
  }
}

.menu {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 3em;

  background-color: var(--back2);
  border-bottom: 1px solid var(--separator);

  transition: all $slow;
  overflow: hidden;
  padding: 0;

  animation-name: menu_rise;
  animation-delay: 2000ms;
  animation-fill-mode: backwards;
  animation-duration: $animation_duration;

  &__items {
    position: absolute;
    top: 6em;
    left: 1em;
    width: 80%;
  }

  &__item {
    cursor: pointer;
    font-weight: 500;
    text-transform: uppercase;
    color: var(--text-color);
    letter-spacing: 2px;
    user-select: none;
    border-bottom: 2px solid transparent;
    transition: all $slow;

    &:hover {
      color: var(--accent);
    }
  }

  &__item + &__item {
    margin: 2em 0 0 0;
  }
}

.opened_menu {
  height: 100%;
}

.selected_menu_item {
  border-bottom: 2px solid var(--accent);
}

@media (min-width: $medium-start) {
  .menu {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 4em;

    .menu_button {
      display: none;
    }

    &__items {
      width: initial;
      position: static;
      display: flex;
      align-items: center;
    }

    &__item + &__item {
      margin: 0 0 0 2em;
    }
  }

  .opened_menu {
    height: 3em;
  }
}