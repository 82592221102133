@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;500&display=swap');

/* Сброс стилей браузера */
html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary,
time, mark, audio, video, button {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    vertical-align: baseline;
    box-sizing: border-box;
    font-family: Roboto, 'sans-serif';
    font-weight: 300;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
    display: block;
}

body {
    line-height: 1.2;
}

ol {
    padding-left: 0;
    list-style: decimal;
}

ul {
    padding-left: 0;
    list-style: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

canvas {
    margin: 0;
    padding: 0;
}