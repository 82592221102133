@import "../../../styles/presets";

.about {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  @include scroll;
  padding: 8em 1em 5em 1em;

  .section_header {
    margin-bottom: 2em;
  }

  &__content {
    max-width: 50em;
    margin-left: auto;
    margin-right: auto;
  }

  p {
    text-align: justify;
    color: var(--text-color);
  }

  p + p {
    margin-top: 0.5em;
  }

  &__links {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 3em;
  }
}