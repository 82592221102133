@import "../../../styles/presets";
@import "../../common/ProjectCard/ProjectCard";
@import "../../common/ProjectFilter/ProjectFilter";

.projects {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  @include scroll;
  padding: 8em 1em 5em 1em;

  .section_header {
    margin-bottom: 2em;
  }

  .project_filter {
    margin-bottom: 2em;
  }

  .project_card {
    margin-top: 1em;
  }

  &__content {
    max-width: 55em;
    margin-left: auto;
    margin-right: auto;
  }

  &__description {
    text-align: justify;
    color: var(--text-color);
    margin-bottom: 2em;
  }
}

@media (min-width: $medium-start) {
  .projects {
    .project_card {
      margin-top: 0;
    }

    &__project_list {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 1em;
    }
  }
}