@import "../../../styles/presets";
@import "../../common/TechLabel/TechLabel";

.project_card {
  padding: 1em;
  border-radius: 3px;
  box-shadow: var(--card-shadow);
  border: 1px solid var(--separator);
  background-color: var(--back2);
  height: 16em;
  transition: all $slow;

  display: flex;
  flex-direction: column;

  .tech_label {
    margin: 5px 5px 0 0;
  }

  &__title_block {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 1rem;

    &:hover .project_card__title_number, &:hover .project_card__title_link {
      color: var(--accent);
    }
  }

  &__title_number {
    writing-mode: tb-rl;
    transform: rotate(180deg);
    color: var(--text-color);
    border-left: 2px solid var(--accent);

    transition: all $slow;
  }

  &__title_link {
    text-decoration: none;
    color: var(--text-color);
    margin-left: 0.5rem;

    font-weight: 500;
    font-size: 1.5em;

    transition: all $slow;
  }

  &__description {
    color: var(--text-color);
    font-weight: 300;
    user-select: none;
    transition: all $slow;
  }

  &__tech_list {
    display: flex;
    flex-wrap: wrap-reverse;
    margin-top: auto;
  }
}