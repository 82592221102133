// Переменные для хранения контрольных точек медиазапросов
$small-end: 768px;
$medium-start: 769px;
$medium-end: 1023px;
$large-start: 1024px;

// Переменные для хранения скоростей
$fast: 150ms;
$normal: 200ms;
$slow: 300ms;

$animation_duration: 500ms;

// Общие стили для кнопок
.button {
  cursor: pointer;
  border: 1px solid var(--border-color);
  border-radius: 3px;
  padding: 5px 25px;
  font-size: 1.2em;
  color: var(--text-color);
  background-color: var(--back2);
  box-shadow: var(--button-shadow);
  transition: all $fast;

  &:hover {
    color: white;
    background-color: var(--accent);
    border: 1px solid var(--accent);
  }

  &:active {
    transform: scale(0.95);
    box-shadow: none;
  }
}

// Общие стили для кнопок со ссылкой
.linked_button {
  border: 1px solid var(--border-color);
  border-radius: 3px;
  padding: 0;
  font-size: 1.2em;
  background-color: var(--back2);
  box-shadow: var(--button-shadow);
  transition: all $fast;

  a {
    display: block;
    padding: 5px 25px;
    text-decoration: none;
    color: var(--text-color);
    transition: all $slow;
  }

  &:hover {
    color: white;
    background-color: var(--accent);
    border: 1px solid var(--accent);

    a {
      color: white;
    }
  }

  &:active {
    transform: scale(0.95);
    box-shadow: none;
  }
}

// Общие стили для заголовков разделов
.section_header {
  font-size: 1.3em;
  text-align: center;
  color: var(--text-color);
  padding-bottom: 0.5em;
  border-bottom: 2px solid var(--accent);
  transition: all $slow;
}

@media (min-width: $medium-start) and (max-width: $medium-end) {
  .section_header {
    font-size: 1.5em;
  }
}

@media (min-width: $large-start) {
  .section_header {
    font-size: 1.7em;
  }
}

// Анимация появления кнопок
@keyframes button_rise {
  from {
    transform: perspective(100px) rotateX(90deg);
  }

  to {
    transform: perspective(100px) rotateX(0);
  }
}

// Анимация появления карточек
@keyframes card_rise {
  from {
    transform: translateY(50px) scale(0.8);
    opacity: 0;
  }

  to {
    transform: translateY(0) scale(1);
    opacity: 1;
  }
}

// Миксин для прокручиваемых элементов
@mixin scroll {
  overflow: auto;

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--thumb-color);
  }

  &:hover::-webkit-scrollbar-thumb {
    background-color: var(--thumb-hover-color);
  }
}
