@import "../../../styles/presets";

.menu_button {
  position: relative;
  width: 3em;
  height: 3em;
  cursor: pointer;

  svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all $slow;

    path {
      transition: all $slow;
      stroke: var(--text-color);
    }
  }
}

.button_for_opened {
  svg:nth-child(1) {
    opacity: 0;
  }

  svg:nth-child(2) {
    transform: rotate(45deg);
  }

  svg:nth-child(3) {
    transform: rotate(-45deg);
  }
}