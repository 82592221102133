@import "../../../styles/presets";

@keyframes pulse {
  from {
    transform: scale(1);
    opacity: 1;
  }

  50% {
    transform: scale(1.5);
    opacity: 0.5;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
}

.preloader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: var(--back2);

  &__pulsar {
    width: 2em;
    height: 2em;
    background-color: var(--accent);
    border-radius: 50%;
    animation-name: pulse;
    animation-duration: 500ms;
    animation-iteration-count: infinite;
  }
}