@import "../../../styles/presets";

.skill_card {
  padding: 1em;
  border-radius: 3px;
  box-shadow: var(--card-shadow);
  border: 1px solid var(--separator);
  background-color: var(--back2);
  transition: all $slow;

  &__card_header {
    display: flex;

    svg {
      width: 2em;
      height: 2em;
    }

    span {
      margin-left: 0.5em;
      font-weight: 500;
      font-size: 1.5em;
      user-select: none;
      color: var(--text-color);
      transition: all $slow;
    }
  }

  &__tech_list {
    margin-top: 1em;
    font-size: 1.2em;

    li {
      border-left: none;
      padding-left: 0;
      cursor: pointer;
      color: var(--text-color);
      user-select: none;
      transition: all $slow;

      &:hover {
        border-left: 4px solid var(--accent);
        padding-left: 0.5em;
      }
    }

    li + li {
      margin-top: 6px;
    }
  }
}