@import "../../../styles/presets";

.project_filter_label {
  margin: 0.5em 0.5em 0 0;
  padding: 3px 15px;
  background-color: whitesmoke;
  border: 1px solid dimgray;
  color: dimgray;
  font-family: sans-serif;
  font-size: 0.9em;
  border-radius: 3px;
  cursor: pointer;
  user-select: none;
  transition: all $slow;
}

.project_filter_label_selected {
  border: 1px solid dodgerblue;
  background-image: linear-gradient(to bottom, deepskyblue, dodgerblue);
  color: white;
}